import React, { useEffect, useState } from "react";
import TMHBanner from "../../components/TMHBanner/TMHBanner";
import TMHFooter from "../../components/TMHFooter/TMHFooter";
import TMHLoader from "../../components/TMHLoader/TMHLoader";
import { useTitle } from 'react-use'
import ReactGA from "react-ga4"
import './InputEmailPage.scss'
import { getUserLocationFromAPI } from "../../apiService"

export default function InputEmailPage() {
    useTitle("The Movie House - Input Email")

    useEffect(() => {
        getUserLocationFromAPI("Display")
        ReactGA.send("pageview")
    })

    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email)
    const [isChecked, setIsChecked] = useState(false)
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [errorText, setErrorText] = useState("")

    async function handleCheckEmail(event) {
        event.preventDefault()
        setIsLoading(true)
        setErrorText("")

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cache-Control", "no-cache");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "email": email,
                "method": "Display"
            }),
        };

        fetch("https://themoviehouse.tv/api/users/tmh_profile/email", requestOptions)
            .then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        ok: response.ok,
                        json,
                    })))
            }).then(
                (result) => {
                    let json = result.json
                    if (result.ok) {
                        localStorage.setItem("email", email)
                        window.location.href = "https://relywandalimbilton.com/click"
                    }
                    else {
                        if (json.message === "EMAIL_REGISTERED") {
                            setErrorText("Email already registered!")
                        }
                        else {
                            setErrorText("Something went wrong. Please try again!")
                        }

                        setIsLoading(false)
                    }
                },
                (error) => {
                    console.error(error)
                    setIsLoading(false)
                }
            )
    }


    return (
        <div>
            <TMHBanner useLogIn={false} />
            <div className="form-wrapper" />
            <div className="input-email-page-center-content">
                <div className="content">
                    <label className="description-30">Let's go!</label>
                    <div className="separator" />
                    {errorText && <label className="red-color description-20">{errorText}</label>}
                    <label className="caption-40">To claim enter your email and accept the terms & conditions</label>
                    <input type="email" value={email} onChange={(e) => {
                        setErrorText("")
                        setEmail(e.target.value)
                    }} className="description-16" placeholder="Submit your email to claim" />
                    <div className="terms-block">
                        <p className="description-20">I Agree to the {" "}
                            <a href="https://themoviehouse.tv/tmh/#/terms-conditions" target="_blank" rel="noreferrer">Terms &#38; conditions</a>
                            {" "} &#38; {" "}
                            <a href="https://themoviehouse.tv/tmh/#/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>
                        </p>
                        <input checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} className="checkbox" type="checkbox" />
                    </div>
                    <label className="description-14">Ensure your details are correct as this is where we will send your code for your new release movie access.</label>
                    <button
                        className="caption-20"
                        onClick={(e) => handleCheckEmail(e)}
                        disabled={!isValidEmail(email) || !isChecked}>
                        CONTINUE
                    </button>
                </div>
            </div>
            <TMHFooter />
            {isLoading && <TMHLoader />}
        </div>
    )
}
