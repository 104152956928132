import { HashRouter, Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage/LandingPage";
import SignUpPage from "../pages/SignUpPage/SignUpPage";
import MovieLandingPage from "../pages/MovieLandingPage/MovieLandingPage";
import InstallAppPage from "../pages/InstallAppPage/InstallAppPage";
import InputEmailPage from "../pages/InputEmailPage/InputEmailPage";

export default function AppRouter() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/:id" element={<LandingPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/input-email" element={<InputEmailPage />} />
        <Route path="/signup&:parameter=:value" element={<SignUpPage />} />
        <Route path="/movie" element={<MovieLandingPage />} />
        <Route path="/install-app" element={<InstallAppPage />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </HashRouter>
  );
}


