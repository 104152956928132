import React, { useEffect } from "react";
import TMHBanner from "../../components/TMHBanner/TMHBanner";
import TMHFooter from "../../components/TMHFooter/TMHFooter";
import { useNavigate } from "react-router-dom"
import { useTitle } from 'react-use'
import ReactGA from "react-ga4"
import './MovieLandingPage.scss'
import RokuMovieImg from "./roku-movie.png"

export default function MovieLandingPage() {
    const navigate = useNavigate()
    useTitle("The Movie House - Movie")

    useEffect(() => {
        ReactGA.send("pageview")
    })
    const isSmallUI = window.innerWidth < 600

    return (
        <div>
            <TMHBanner useLogIn={false} />
            <div className="form-wrapper" />
            <div className="movie-page-center-content">
                <div className="content">
                    <div className="caption-60">CONGRATULATIONS!</div>
                    <div className="separator" />
                    {isSmallUI && <div className="red-color caption-60"> YOU WON A FREE MOVIE! </div>}
                    <img src={RokuMovieImg} alt="Roku" />
                    {!isSmallUI && <div className="red-color caption-60"> YOU WON A FREE MOVIE! </div>}
                    <div className="description-40">For your Roku Device!</div>
                    <button className="caption-20" onClick={() => navigate("/input-email")} >
                        CLAIM NOW
                    </button>
                </div>
            </div>
            <TMHFooter />
        </div>
    )
}
