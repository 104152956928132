import React from "react";
import TMHBanner from "../../components/TMHBanner/TMHBanner";
import TMHLoader from "../../components/TMHLoader/TMHLoader";
import { useTitle } from 'react-use'
import './InstallAppPage.scss'
import { checkSupportAndroidVersion, checkSupportIOSVersion } from "../../apiService"

export default function InstallAppPage() {
    useTitle("The Movie House")
    var platform = checkSupportAndroidVersion() ? "Android" : checkSupportIOSVersion() ? "iOS" : "Desktop"

    if (platform === "Android"){
        window.location.href = "https://play.google.com/store/apps/details?id=com.roku.movieremote"
    } else if (platform === "iOS"){
        window.location.href = "https://apps.apple.com/ky/app/roku-movie-remote/id6447076515"
    } else {
        window.location.href = "https://freemoviespin.com/"
    }

    return (
        <div>
            <TMHBanner useLogIn={false} />
            <TMHLoader />
        </div>
    )
}
