import React from "react";
import "./TMHFooterStyles.scss";

function TMHFooter() {
  return(
    <nav className="wrapper-footer">
      <a href="https://themoviehouse.tv/tmh/#/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>
      <a href="https://themoviehouse.tv/tmh/#/terms-conditions" target="_blank" rel="noreferrer">Terms &#38; conditions</a>
      <a href="https://themoviehouse.tv/tmh/#/about" target="_blank" rel="noreferrer">About The Movie House</a>
    </nav>
  ) 
}

export default TMHFooter;