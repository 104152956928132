import AppRouter from "./routers/AppRouter";
import ReactGA from 'react-ga4'

const TRACKING_ID = "G-HQ31V5EX97"
ReactGA.initialize(TRACKING_ID)

function App() {
  return (
    <AppRouter />
  );
}

export default App;
