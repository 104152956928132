import React from "react";
import "./TMHBannerStyles.scss";

function TMHBannerWrapper({ children, ...restProps }) {
  return (
    <div {...restProps} >
      <header className="wrapper-banner-gradient">
        {children}
      </header>
    </div>
  );
}

export default TMHBannerWrapper;
