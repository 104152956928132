export const getUserLocationFromAPI = async (method="FreeMovieHouse") => {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && true) {
        return new Promise((resolve) => resolve('US'))
    } else {
        var myHeaders = new Headers();
        myHeaders.append("Cache-Control", "no-cache");
        const response = await fetch("https://themoviehouse.tv/api/users/get_country_code?source=" + method, {
            method: 'GET',
            headers: myHeaders
        })

        return new Promise((resolve) => response.json()
            .then((json) => {
                if (response.ok) {
                    resolve(json.data)
                }
                else {
                    resolve('US')
                }
            }))
    }
}

export const checkSupportAndroidVersion = () => {
    var ua = navigator.userAgent;
    var isAndroid = /(android)/i.test(ua)

    if (!isAndroid) { return false }
    var match = ua.toLowerCase().match(/android\s([0-9.]*)/i);
    if (!match) { return false }

    return true;

    // var maximumAndroidVersionSupported = 9999
    // var androidVersion = parseInt(match[1])
    // return androidVersion <= maximumAndroidVersionSupported;
}

export const checkSupportIOSVersion = () => {
    return !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
}
