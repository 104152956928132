
import React, { useEffect, useState } from "react";
import TMHBanner from "../../components/TMHBanner/TMHBanner";
import TMHFooter from "../../components/TMHFooter/TMHFooter";
import TMHLoader from "../../components/TMHLoader//TMHLoader";
import { useTitle } from 'react-use'
import ReactGA from "react-ga4"
import './SignUpPage.scss'
import { checkSupportAndroidVersion, checkSupportIOSVersion } from "../../apiService"
import { useNavigate, useParams } from "react-router-dom"
const savedEmail = localStorage.getItem("email") ?? ""

export default function SignUpPage() {
    useTitle("The Movie House - SignUp")
    localStorage.removeItem("email")

    const navigate = useNavigate()
    let { value } = useParams();

    useEffect(() => {
        const platform = checkSupportAndroidVersion() ? "Android" : checkSupportIOSVersion() ? "iOS" : "Desktop"
        if (platform !== "Desktop") {
            if (value) localStorage.setItem("postBack-cid", value)
            navigate("/")
        } else {
            ReactGA.send("pageview")
        }
    })

    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email)
    const [isChecked, setIsChecked] = useState(false)
    const [email, setEmail] = useState(savedEmail)
    const [isLoading, setIsLoading] = useState(false)
    const [errorText, setErrorText] = useState("")

    const [passwordPage, setPasswordPage] = useState(savedEmail !== "")
    const [password, setPassword] = useState("")

    async function handleSubmit(event) {
        event.preventDefault()
        setIsLoading(true)
        setErrorText("")

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cache-Control", "no-cache");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "username": email,
                "password": password
            }),
        };

        fetch("https://themoviehouse.tv/api/users/register", requestOptions)
            .then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        ok: response.ok,
                        json,
                    })))
            }).then(
                (result) => {
                    let json = result.json
                    if (result.ok) {
                        var redirectLink = "https://themoviehouse.tv/tmh/#/login-with-token/" + json.token
                        if (value) redirectLink = redirectLink + "/" + value
                        window.location.href = redirectLink
                    }
                    else {
                        setPasswordPage(false)
                        setErrorText(json.message)
                    }
                },
                (error) => {
                    console.error(error)
                    setPasswordPage(false)
                }
            ).finally(() => {
                setIsLoading(false)
            })
    }

    async function handleCheckEmail(event) {
        event.preventDefault()
        setIsLoading(true)

        setErrorText("")
        setPassword("")

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cache-Control", "no-cache");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "email": email,
                "method": "FreeMovieHouse"
            }),
        };

        fetch("https://themoviehouse.tv/api/users/tmh_profile/email", requestOptions)
            .then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        ok: response.ok,
                        json,
                    })))
            }).then(
                (result) => {
                    let json = result.json
                    if (result.ok) {
                        setPasswordPage(true)
                    }
                    else {
                        setPasswordPage(false)
                        if (json.message === "EMAIL_REGISTERED") {
                            setErrorText("Email already registered!")
                        }
                        else {
                            setErrorText("Something went wrong. Please try again!")
                        }
                    }
                },
                (error) => {
                    console.error(error)
                    setPasswordPage(false)
                }
            ).finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div>
            <TMHBanner useLogIn={false} />
            <div className="form-wrapper" />
            <div className="sign-up-page-center-content">
                {
                    passwordPage ?
                        <div className="content">
                            <label className="description-30">You're nearly there.. </label>
                            <div className="separator" />

                            <label className="caption-50">
                                <span className="red-color">A Movie House </span> Account is ready for you.
                            </label>
                            <label className="caption-40">Create a password to proceed.</label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="description-16" placeholder="Password" />
                            <button onClick={(e) => handleSubmit(e)} disabled={password.length < 6} className="caption-20">LET'S GO!</button>
                        </div>
                        :
                        <div className="content">
                            <label className="description-30">Let's go!</label>
                            <div className="separator" />
                            {errorText && <label className="red-color description-20">{errorText}</label>}
                            <label className="caption-40">To claim enter your email and accept the terms & conditions</label>
                            <input type="email" value={email} onChange={(e) => {
                                setErrorText("")
                                setEmail(e.target.value)
                            }} className="description-16" placeholder="Submit your email to claim" />
                            <div className="terms-block">
                                <p className="description-20">I Agree to the {" "}
                                    <a href="https://themoviehouse.tv/tmh/#/terms-conditions" target="_blank" rel="noreferrer">Terms &#38; conditions</a>
                                    {" "} &#38; {" "}
                                    <a href="https://themoviehouse.tv/tmh/#/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>
                                </p>
                                <input checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} className="checkbox" type="checkbox" />
                            </div>

                            <label className="description-14">Ensure your details are correct as this is where we will send your code for your new release movie access.</label>
                            <button onClick={(e) => handleCheckEmail(e)} disabled={!isValidEmail(email) || !isChecked} className="caption-20">CONTINUE</button>
                        </div>
                }
            </div>
            <TMHFooter />
            {isLoading && <TMHLoader />}
        </div>
    )
}
