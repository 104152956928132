import React, { useEffect, useState } from "react";
import TMHBanner from "../../components/TMHBanner/TMHBanner";
import TMHFooter from "../../components/TMHFooter/TMHFooter";
import RokuImg from './roku.png'
import CHPlayImg from './google-play.png'
import AppStoreImg from './app-store.png'
import TMHProfileLogo from './tmh-profile-logo.png'
import Popup from 'reactjs-popup'
import { getUserLocationFromAPI, checkSupportAndroidVersion, checkSupportIOSVersion } from "../../apiService"
import { useTitle } from 'react-use'
import ReactGA from "react-ga4"
import './LandingPage.scss'

export default function LandingPage() {
    const [openPopup, setOpenPopup] = useState(false)
    var platform = checkSupportAndroidVersion() ? "Android" : checkSupportIOSVersion() ? "iOS" : "Desktop"

    var title = "The Movie House"
    if (window.location.href.toLocaleLowerCase().includes("/movie")) {
        title = "The Movie House - Movie"
        platform = "movie"
    } else if (window.location.href.toLocaleLowerCase().includes("/search")) {
        title = "The Movie House - Search"
    } else if (platform === "Android") {
        title = "The Movie House - Android"
    } else if (platform === "iOS") {
        title = "The Movie House - iOS"
    }
    useTitle(title)

    useEffect(() => {
        const initData = async () => {
            var locationData = await getUserLocationFromAPI()
            if (locationData.toUpperCase() === "US") {

            } else {
                setOpenPopup(true)
            }
        }

        ReactGA.send("pageview")
        initData()
    })


    function handleMobileLinkClicked() {
        var cid = localStorage.getItem("postBack-cid")
        if (cid) {
            var postBackLink = "https://relywandalimbilton.com/postback?cid=" + cid
            console.log(cid)
            window.open(postBackLink, 'hiddenFrame')
            localStorage.removeItem("postBack-cid")
        }
    }

    return (
        <div>
            <TMHBanner useLogIn={platform !== "movie"} />
            <iframe title="hidden frame" hidden name="hiddenFrame" />
            {
                (platform === "Android") ?
                    <a href="https://play.google.com/store/apps/details?id=com.roku.movieremote&referrer=in_house%3Dtrue" onClick={() => handleMobileLinkClicked()} target="_blank" rel="noreferrer" className="landing-center-content">
                        <img className="landing-page-roku-image" src={RokuImg} alt="Roku" />
                        <div className="landing-page-title">
                            <div>
                                <span>Download the </span>
                                <span className="red-color">Roku Movie  </span>
                            </div>
                            <div>
                                <span className="red-color">Remote </span>
                                <span>app from the <b>play</b></span>
                            </div>
                            <div>
                                <span> <b>store</b> to redeem your Free</span>
                            </div>
                            <div>
                                <span>New Release Movie.</span>
                            </div>
                        </div>
                        <img className="landing-page-tmh-profile-logo" src={TMHProfileLogo} alt="TMH Profile Logo" />
                        <div className="landing-page-play-store">Download from the play store now</div>
                        <div className="landing-page-ch-play-button">
                            <img className="landing-page-ch-play" src={CHPlayImg} alt="Google Play" />
                        </div>
                    </a>
                    : (platform === "iOS") ? <a href="https://apps.apple.com/ky/app/roku-movie-remote/id6447076515" onClick={() => handleMobileLinkClicked()} target="_blank" rel="noreferrer" className="landing-center-content">
                        <img className="landing-page-roku-image" src={RokuImg} alt="Roku" />
                        <div className="landing-page-title">
                            <div>
                                <span>Download the </span>
                                <span className="red-color">Roku Movie  </span>
                            </div>
                            <div>
                                <span className="red-color">Remote </span>
                                <span>app from the <b>play</b></span>
                            </div>
                            <div>
                                <span> <b>store</b> to redeem your Free</span>
                            </div>
                            <div>
                                <span>New Release Movie.</span>
                            </div>
                        </div>
                        <img className="landing-page-tmh-profile-logo" src={TMHProfileLogo} alt="TMH Profile Logo" />
                        <div className="landing-page-play-store">Download from the App Store now</div>
                        <div className="landing-page-app-store-button">
                            <img className="landing-page-app-store" src={AppStoreImg} alt="AppStore Icon" />
                        </div>
                    </a> :
                        <div className="landing-center-content">
                            <img className="landing-page-roku-image" src={RokuImg} alt="Roku" />
                            <div className="landing-page-title">
                                <div>
                                    <span>Unlimited free </span>
                                    <span className="red-color">movies &#38; TV</span>
                                </div>
                                <div>
                                    <span className="red-color">shows </span>
                                    <span>on your Roku device</span>
                                </div>
                            </div>
                            <a href="#/signup" rel="noreferrer" className="landing-page-sign-up-button">Sign up &#38; watch now !</a>
                            <a href="#/signup" rel="noreferrer" className="landing-page-let-go-button">
                                <div className="landing-page-let-go-text"> I have a ROKU, lets GO! </div>
                            </a>
                        </div>
            }

            <TMHFooter />

            <Popup
                onClose={() => {
                    window.open("about:blank", "_self");
                    window.close();
                }}
                modal
                open={openPopup}
                overlayStyle={{
                    "backgroundColor": "#000000B2"
                }}
            >
                <div className="modal-geo-block">
                    <div className="content">
                        <div className="title"><b>Apologies....</b></div>
                        <div className="description">
                            We currently only support customers inside the USA. We continue to expand our services so please check back in the future
                        </div>

                        <button className="close-button" onClick={() => {
                            window.open("about:blank", "_self");
                            window.close();

                        }}>Close window</button>
                    </div>
                </div>
            </Popup>
        </div>
    )
}
