import React from "react"
import BannerWrapper from "./TMHBannerWrapper"
import BannerNavBar from "./TMHBannerNavBar"
import LogoImg from "./logo.png"

function TMHBanner({ children, classOption = "wrapper-banner", useLogIn=true }) {
  return (
    <>
      <BannerWrapper className={classOption} />
      <BannerNavBar className="navbar-banner">
        <div>
          <a href="/">
            <img className="logo" src={LogoImg} alt="Movie House logo" />
          </a>
        </div>
        { useLogIn && <a className="login-button" href="https://themoviehouse.tv/tmh/#/login" target="_blank" rel="noreferrer">
          <div className="login-text">Log In</div>
        </a> }
      </BannerNavBar>

      {children}
    </>
  )
}

export default TMHBanner
