import React from "react";
import "./TMHLoaderStyles.css";
import LoaderImg from "./loader.png"

function TMHLoader({ children, ...restProps }) {
  return (
    <div className="loader-background">
      <img className="loader" src={LoaderImg} alt="tmh-loader" />
    </div>
  );
}

export default TMHLoader;
